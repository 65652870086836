










































import useEvent from "@/use/event";
import useParticipant from "@/use/participant";
import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import countriesList from "@/assets/data/countries.json";
import useMisc from "@/use/misc";

export default defineComponent({
  components: {
    MParticipantListItem: () =>
      import("@/components/molecules/participant/m-participant-list-item.vue"),
  },
  setup(_, { root }) {
    const {
      getParticipantStatusName,
      getParticipantStatusColor,
      getParticipantInvoiceType,
    } = useParticipant({ root });
    const { getEventTypeColor } = useEvent({ root });
    const { getLanguageVersion } = useMisc({ root });

    const state = reactive({
      expanded: [],
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "event", text: "Wydarzenie", sortable: false },
        { value: "image", text: "", width: 1 },
        { value: "lastName", text: "Nazwisko" },
        { value: "firstName", text: "Imię" },
        { value: "state", text: "Stan" },
        { value: "phoneNumber", text: "Telefon", sortable: false },
        { value: "alert", text: "", align: "right", width: 1 },
        { value: "brief", text: "", align: "right", width: 1 },
        { value: "invoice", text: "", align: "right", width: 1 },
        { value: "payment", text: "", align: "right", width: 1 },
      ],
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      searchTimeout: undefined as undefined | number,
    });

    const search = () => {
      const search = root.$route.query.q;

      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("participant/global", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            event: root.$route.params.id,
            search,
          },
        })
        .then(({ data: { participants, total } }) => {
          state.items = participants.map((participant: any, index: number) => ({
            id: index,
            name: "",
            ...participant,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    watch(() => root.$route.query.q, search);
    watch(() => state.options, search, { deep: true });

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/participant/`
    );

    return {
      state,
      getParticipantStatusName,
      getParticipantStatusColor,
      getParticipantInvoiceType,
      filesUrl,
      getEventTypeColor,
      countriesList,
      getLanguageVersion,
    };
  },
});
