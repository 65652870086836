var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"o-search-results"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":"","exact":"","to":{ name: 'panel.dashboard' }}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Lista uczestników")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-4",staticStyle:{"max-width":"750px"},attrs:{"append-icon":"mdi-magnify","label":"Szukaj","solo":"","dense":"","flat":"","single-line":"","hide-details":"","background-color":"grey lighten-4","value":_vm.$route.query.q},on:{"input":function (q) { return _vm.$router.push({ name: 'panel.search', query: { q: q } }); }}})],1),_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] },"expanded":_vm.state.expanded},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"update:expanded":function($event){return _vm.$set(_vm.state, "expanded", $event)},"click:row":function (_, ref) {
	var expand = ref.expand;
	var isExpanded = ref.isExpanded;

	return expand(!isExpanded);
}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.event",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getEventTypeColor(item.event.type)}},[_vm._v(_vm._s(item.event.name))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":item.image ? _vm.filesUrl + item.image.name : require('@/assets/avatar.png')}})],1)]}},{key:"item.firstName",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.firstName))]}},{key:"item.lastName",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.lastName))]}},{key:"item.state",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getParticipantStatusColor(item.state)}},[_vm._v(_vm._s(_vm.getParticipantStatusName(item.state)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('m-participant-list-item',{attrs:{"item":item,"headersLength":headers.length,"currency":_vm.state.currency,"specializations":_vm.state.specializations}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }